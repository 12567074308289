@font-face {
 font-family: 'Fraunces';
 src: url('./assets/Fraunces-SemiBold.ttf') format('opentype');
}

@font-face {
 font-family: 'Hind';
 font-style: normal;
 font-weight: normal;
 src: local('Hind Regular'), url('./assets/Hind-Regular.ttf')  format('opentype');
}


@font-face {
 font-family: 'Hind';
 font-style: normal;
 font-weight: 200;
 src: local('Hind Light'), url('./assets/Hind-Light.ttf') format('opentype');
}


@font-face {
 font-family: 'Hind';
 font-style: normal;
 font-weight: 500;
 src: local('Hind Medium'), url('./assets/Hind-Medium.ttf') format('opentype');
}


@font-face {
 font-family: 'Hind';
 font-style: normal;
 font-weight: 600;
 src: local('Hind SemiBold'), url('./assets/Hind-SemiBold.ttf') format('opentype');
}


@font-face {
 font-family: 'Hind';
 font-style: normal;
 font-weight: bold;
 src: local('Hind Bold'), url('./assets/Hind-Bold.ttf') format('opentype');
}